<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-calendar"
        title="รอบบรรจุ : การแต่งตั้งคณะกรรมการประเมิน ตำแหน่งครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card>
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <v-chip class="ma-2" color="pink" label text-color="white">
                <v-icon left>
                  mdi-account-multiple-check
                </v-icon>
                เรียกรายงานตัว {{ countData.countsPerson }}
              </v-chip>


              <v-chip class="ma-2" color="success" label text-color="white">
                <v-icon left>
                  mdi-account-group
                </v-icon>
                ปัจจุบัน {{ countData.countsPersonMain }}
              </v-chip>

              <v-chip class="ma-2" color="warning" label text-color="white">
                <v-icon left>
                  mdi-account-multiple-minus
                </v-icon>
                ออก {{ countData.total }}
              </v-chip>
              <v-btn @click="updateassistantcommitteequalified()" small outlined color="success">
                <v-icon>mdi-update</v-icon> Update รายชื่อกรรมการ
              </v-btn>


            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="manage_assistant_teachers"
                :loading="loading"
              >
                <template v-slot:top>
                  <div>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="ค้นหา"
                      single-line
                      hide-details
                      filled
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="purple"
                      small
                      fab
                      :to="
                        '/admin/personnel_temporary_assisstant/?appoin_day=' +
                          item.appoin_day +
                          '&appoin_month=' +
                          item.appoin_month +
                          '&appoin_year=' +
                          item.appoin_year
                      "
                      ><v-icon>mdi-account-circle</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions2="{ item }">
                  <div>
                    <v-btn
                      color="#827717"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_report/manage_assistant_teacher_committee/?appoin_day=' +
                          item.appoin_day +
                          '&appoin_month=' +
                          item.appoin_month +
                          '&appoin_year=' +
                          item.appoin_year
                      "
                      ><v-icon>mdi-numeric-2-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions3="{ item }">
                  <div>
                    <v-btn
                      color="pink"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher/?appoin_day=' +
                          item.appoin_day +
                          '&appoin_month=' +
                          item.appoin_month +
                          '&appoin_year=' +
                          item.appoin_year
                      "
                      ><v-icon>mdi-numeric-3-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions4="{ item }">
                  <div>
                    <v-btn
                      color="info"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_detail/?appoin_day=' +
                          item.appoin_day +
                          '&appoin_month=' +
                          item.appoin_month +
                          '&appoin_year=' +
                          item.appoin_year
                      "
                      ><v-icon>mdi-numeric-4-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.actions5="{ item }">
                  <div>
                    <v-btn
                      color="#1B5E20"
                      small
                      fab
                      :to="
                        '/admin/assistant_teacher_report/?appoin_day=' +
                          item.appoin_day +
                          '&appoin_month=' +
                          item.appoin_month +
                          '&appoin_year=' +
                          item.appoin_year
                      "
                      ><v-icon>mdi-numeric-4-box</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.type_personnel="{ item }">
                  <div>
                    <v-chip
                      color="info"
                      v-if="item.type_personnel === 'recruit'"
                      >รอบแข่งขัน</v-chip
                    >
                    <v-chip
                      color="accent"
                      v-else-if="item.type_personnel === 'select'"
                      >รอบคัดเลือก</v-chip
                    >
                    <v-chip
                      color="deep"
                      dark
                      v-else-if="item.type_personnel === 'transfer_position'"
                      >รอบโอน</v-chip
                    >
                    <v-chip
                      color="secondary"
                      v-else-if="item.type_personnel === 'local_dev'"
                      >รอบคืนถิ่น</v-chip
                    >
                  </div>
                </template>

                <template v-slot:item.clearData="{ item }">
                  <div>
                    <v-btn
                      fab
                      small
                      outlined
                      color="red"
                      @click="
                        clearData(
                          item.appoin_day,
                          item.appoin_month,
                          item.appoin_year
                        )
                      "
                      ><v-icon>mdi-notification-clear-all</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <div>
                    <v-btn
                      fab
                      small
                      rounded
                      color="warning"
                      @click="
                        settingTypePersonnel(
                          item.appoin_day,
                          item.appoin_month,
                          item.appoin_year
                        )
                      "
                    >
                      <v-icon>mdi-tooltip-edit</v-icon></v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.month6="{ item }">
                  <div>
                    {{ item.month6 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month12="{ item }">
                  <div>
                    {{ item.month12 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month18="{ item }">
                  <div>
                    {{ item.month18 | moment("D MMMM YYYY") }}
                  </div>
                </template>

                <template v-slot:item.month24="{ item }">
                  <div>
                    {{ item.month24 | moment("D MMMM YYYY") }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </base-material-card>

      <!--updateTypePersonneldialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="updateTypePersonneldialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="ปรับปรุงข้อมูลประเภทการบรรจุ"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="updateTypePersonnelform" lazy-validation>
                <v-container grid-list-md>
                  <div>
                    วันที่บรรจุ :
                    {{
                      updateTypePersonnels.appoin_day +
                        "-" +
                        updateTypePersonnels.appoin_month +
                        "-" +
                        updateTypePersonnels.appoin_year
                    }}
                  </div>
                  <div>ตำแหน่ง : {{ updateTypePersonnels.position_name }}</div>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        label="รอบการบรรจุ"
                        v-model="updateTypePersonnels.type_personnel"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click.stop="updateTypePersonneldialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                color="success"
                @click.stop="updateTypePersonnelsSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Assistant_teacher_bar from "../../../components/admin/assistant_teacher_bar.vue";
export default {
  name: "HRvecPersonnelTemporaryAssistantPeriod",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      search: "",
      headers: [
        { text: "ตรวจสอบบุคคล", align: "center", value: "actions" },
        { text: "เสนอกรรมการ", align: "center", value: "actions2" },
        { text: "การประเมิน", align: "center", value: "actions3" },
      /*   { text: "การประเมิน", align: "center", value: "actions4" }, */
        { text: "รายงาน", align: "center", value: "actions5" },
        { text: "รอบวันเดือนปี", align: "center", value: "dateApps" },
        { text: "เงื่อนไข", align: "center", value: "type_personnel" },
        { text: "กำหนด", align: "center", value: "action" },

        { text: "เรียก รายงานตัว", align: "center", value: "countManage" },
        {
          text: "ปัจจุบัน ณ สถานศึกษา",
          align: "center",
          value: "countAssistants"
        },
        { text: "จัดการข้อมูล", align: "center", value: "clearData" },
        { text: "ครบ 4 ครั้ง", align: "center", value: "countSuccess" },
        { text: "กำหนด ครั้งที่ 1", align: "center", value: "month6" },
        { text: "กำหนด ครั้งที่ 2", align: "center", value: "month12" },
        { text: "กำหนด ครั้งที่ 3", align: "center", value: "month18" },
        { text: "กำหนด ครั้งที่ 4", align: "center", value: "month24" }
      ],
      manage_assistant_teachers: [],
      type_personnelApp: [
        { text: "รอบคัดเลือก", value: "select" },
        { text: "รอบแข่งข้น", value: "recruit" },
        { text: "รอบคืนถิ่น", value: "local_dev" },
        { text: "รอบโอน", value: "transfer_position" }
      ],
      updateTypePersonnels: [],
      updateTypePersonneldialog: false,
      countData: []
    };
  },
  async mounted() {
    await this.manage_assistant_teacherQuery();
  },
  methods: {
    async updateassistantcommitteequalified(){
      this.loading = true
      let result = await this.$http.post("assistantcommitteequalified.updateToM.php").finally(() => (this.loading = false));  
         
      if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });          
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
    },
    async clearData(appoin_day, appoin_month, appoin_year) {
      let dataText =
        "วันที่บรรจุ :" + appoin_day + "-" + appoin_month + "-" + appoin_year;
      let dataApi = {
        ApiKey: this.ApiKey,
        mt_appoin_day: appoin_day,
        mt_appoin_month: appoin_month,
        mt_appoin_year: appoin_year
      };
      Swal.fire({
        title: "ต้องการจัดการข้อมูลให้ระบบรายงานตัวตรงกับฐานข้อมูลหลัก ? ",
        text: dataText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultClear = await this.$http.post(
            "manage_assistant_teacher.delete.whonull.php",
            dataApi
          );

          if (resultClear.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.manage_assistant_teacherQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async manage_assistant_teacherQuery() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          calendar_admin: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let countsPerson = 0;
      let countsPersonMain = 0;
      data.forEach(value => {
        countsPerson += parseInt(value.countManage);
        countsPersonMain += parseInt(value.countAssistants);
      });
      this.countData.countsPerson = countsPerson;
      this.countData.countsPersonMain = countsPersonMain;
      this.countData.total = parseInt(countsPerson - countsPersonMain);
    },

    async settingTypePersonnel(appoin_day, appoin_month, appoin_year) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        appoin_day: appoin_day,
        appoin_month: appoin_month,
        appoin_year: appoin_year,
        group_date: "ok"
      });
      this.updateTypePersonnels = result.data;

      this.updateTypePersonneldialog = true;
    },
    async updateTypePersonnelsSubmit() {
      if (this.$refs.updateTypePersonnelform.validate()) {
        this.updateTypePersonnels.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "personnel_temporary.update.calendar.php",
          this.updateTypePersonnels
        );

        if (result.data.status == true) {
          Swal.fire({
            title: "ดำเนินการข้อมูลเรียบร้อย",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          });
          await this.manage_assistant_teacherQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.updateTypePersonneldialog = false;
      }
    }
  },
  components: { Assistant_teacher_bar }
};
</script>

<style lang="scss" scoped></style>
